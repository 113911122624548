import { Action, State, Selector, createSelector } from '@ngxs/store';
import type { StateContext } from '@ngxs/store';
import { StateRepository } from '@angular-ru/ngxs/decorators';
import { LoadQualityIssueKinds } from './quality-issue-kinds.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AsyncStorage } from '@store/plugins/async-storage-plugin';
import { QualityIssueKind, QualityIssuesService } from '@api';
import { comparator } from '@shared/utils/comparator.util';
import { Observable } from 'rxjs';

function qualityIssueKindComparator(a: QualityIssueKind, b: QualityIssueKind): number {
  const compareGroup = comparator(a.group, b.group);
  if (compareGroup === 0) {
    return comparator(a.description, b.description);
  }
  return compareGroup;
}

@AsyncStorage
@StateRepository()
@State<QualityIssueKind[]>({
  name: 'qualityIssueKinds',
  defaults: [],
})
@Injectable()
export class QualityIssueKindsState {
  constructor(private qualityIssuesService: QualityIssuesService) {}

  @Selector()
  public static qualityIssueKinds(state: QualityIssueKind[]): QualityIssueKind[] {
    return state || [];
  }

  @Selector([QualityIssueKindsState.qualityIssueKinds])
  public static firstQualityIssueKind(issueKinds: QualityIssueKind[]): QualityIssueKind {
    return issueKinds[0];
  }

  public static getIssueKindId(issueKindDescription: string): (state: QualityIssueKind[]) => number {
    return createSelector(
      [QualityIssueKindsState.qualityIssueKinds],
      (issueKinds: QualityIssueKind[]) =>
        issueKinds.find((issueKind: QualityIssueKind) => issueKind.description === issueKindDescription)?.id
    );
  }

  @Action(LoadQualityIssueKinds)
  public loadQualityIssueKinds(ctx: StateContext<QualityIssueKind[]>): Observable<QualityIssueKind[]> {
    return this.qualityIssuesService.apiQualityIssuesKindsGet$Json().pipe(
      tap((list) => {
        console.log('✎: [line 52][ISSUE KINDS] list: ', list);
        ctx.setState(list.sort(qualityIssueKindComparator));
      })
    );
  }
}
